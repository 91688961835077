<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Introduction: Landrick </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a routerLink="/forums">Forums</a></li>
                <li class="breadcrumb-item active" aria-current="page">Forum Topic</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Start Forums -->
<section class="section">
  <div class="container">
    <div class="row">
      <div class="col-lg-8">
        <div class="table-responsive bg-white shadow rounded">
          <table class="table mb-0 table-center">
            <thead class="bg-light">
              <tr>
                <th scope="col" style="min-width: 300px;">Topics</th>
                <th scope="col" class="text-center" style="width: 100px;">Comments</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <div class="media">
                    <i class="uil uil-comment text-muted h5"></i>
                    <div class="content ml-3">
                      <a routerLink="/forums-comments" class="forum-title text-primary font-weight-bold">Changelog</a>
                      <p class="text-muted small mb-0 mt-2">Write by: <a href="javascript:void(0)"
                          class="text-dark font-weight-bold">Calvin Carlo</a></p>
                    </div>
                  </div>
                </td>
                <td class="text-center small">2</td>
              </tr>

              <tr>
                <td>
                  <div class="media">
                    <i class="uil uil-comment text-muted h5"></i>
                    <div class="content ml-3">
                      <a routerLink="/forums-comments"
                        class="forum-title text-primary font-weight-bold">Documentation</a>
                      <p class="text-muted small mb-0 mt-2">Write by: <a href="javascript:void(0)"
                          class="text-dark font-weight-bold">George Meta</a></p>
                    </div>
                  </div>
                </td>
                <td class="text-center small">4</td>
              </tr>

              <tr>
                <td>
                  <div class="media">
                    <i class="uil uil-comment text-muted h5"></i>
                    <div class="content ml-3">
                      <a routerLink="/forums-comments" class="forum-title text-primary font-weight-bold">Components</a>
                      <p class="text-muted small mb-0 mt-2">Write by: <a href="javascript:void(0)"
                          class="text-dark font-weight-bold">Crista Joseph</a></p>
                    </div>
                  </div>
                </td>
                <td class="text-center small">1</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-4 pt-2">
          <div class="rounded shadow p-4">
            <form class="login-form">
              <div class="row">
                <div class="col-lg-12">
                  <div class="form-group position-relative">
                    <label>Your Email <span class="text-danger">*</span></label>
                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                    <input type="email" class="form-control pl-5" placeholder="Email" name="email" required="">
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="form-group position-relative">
                    <label>Password <span class="text-danger">*</span></label>
                    <i-feather name="key" class="fea icon-sm icons"></i-feather>
                    <input type="password" class="form-control pl-5" placeholder="Password" required="">
                  </div>
                </div>

                <div class="col-lg-12">
                  <div class="d-flex justify-content-between">
                    <div class="form-group">
                      <div class="custom-control custom-checkbox">
                        <input type="checkbox" class="custom-control-input" id="customCheck2">
                        <label class="custom-control-label" for="customCheck2">Remember me</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 mb-0">
                  <button class="btn btn-primary btn-block">Login Now</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 mt-4 mt-lg-0 pt-2 pt-lg-0">
        <div class="card border-0 sidebar sticky-bar rounded shadow bg-light">
          <div class="card-body">
            <!-- SEARCH -->
            <div class="widget mb-4 pb-2">
              <div id="search2" class="widget-search mb-0">
                <form role="search" method="get" id="searchform" class="searchform">
                  <div>
                    <input type="text" class="border rounded" name="s" id="s" placeholder="Search forum...">
                    <input type="submit" id="searchsubmit" value="Search">
                  </div>
                </form>
              </div>
            </div>
            <!-- SEARCH -->

            <!-- CATAGORIES -->
            <div class="widget mb-4 pb-2">
              <h4 class="widget-title">Login</h4>
              <form class="login-form mt-4">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group position-relative">
                      <label>Your Email <span class="text-danger">*</span></label>
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input type="email" class="form-control pl-5" placeholder="Email" name="email" required="">
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="form-group position-relative">
                      <label>Password <span class="text-danger">*</span></label>
                      <i-feather name="key" class="fea icon-sm icons"></i-feather>
                      <input type="password" class="form-control pl-5" placeholder="Password" required="">
                    </div>
                  </div>

                  <div class="col-lg-12">
                    <div class="d-flex justify-content-between">
                      <div class="form-group">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="customCheck1">
                          <label class="custom-control-label" for="customCheck1">Remember me</label>
                        </div>
                      </div>
                      <p class="forgot-pass mb-0"><a routerLink="/auth-re-password-three"
                          class="text-dark font-weight-bold">Forgot password ?</a></p>
                    </div>
                  </div>
                  <div class="col-lg-12 mb-0">
                    <button class="btn btn-primary btn-block">Sign in</button>
                  </div>
                  <div class="col-12 text-center">
                    <p class="mb-0 mt-4"><small class="text-dark mr-2">Don't have an account ?</small> <a
                        routerLink="/auth-signup-three" class="text-dark font-weight-bold">Sign Up</a></p>
                  </div>
                </div>
              </form>
            </div>
            <!-- CATAGORIES -->

            <!-- RECENT POST -->
            <div class="widget mb-4 pb-2">
              <h4 class="widget-title">Recent Replies</h4>
              <ul class="list-unstyled mb-0 mt-4">
                <li class="text-muted small h6">
                  <i-feather name="user" class="fea icon-sm"></i-feather>
                  <a href="javascript:void(0)" class="text-dark ml-2">Ameliya</a> on <a href="javascript:void(0)"
                    class="text-primary">Privacy policy</a>
                </li>
                <li class="text-muted small h6">
                  <i-feather name="user" class="fea icon-sm"></i-feather>
                  <a href="javascript:void(0)" class="text-dark ml-2">Crista</a> on <a href="javascript:void(0)"
                    class="text-primary">HTML Template hosts</a>
                </li>
                <li class="text-muted small h6">
                  <i-feather name="user" class="fea icon-sm"></i-feather>
                  <a href="javascript:void(0)" class="text-dark ml-2">John</a> on <a href="javascript:void(0)"
                    class="text-primary">Specific error messages</a>
                </li>
                <li class="text-muted small h6">
                  <i-feather name="user" class="fea icon-sm"></i-feather>
                  <a href="javascript:void(0)" class="text-dark ml-2">Jafrin</a> on <a href="javascript:void(0)"
                    class="text-primary">Network administration</a>
                </li>
                <li class="text-muted small h6 mb-0">
                  <i-feather name="user" class="fea icon-sm"></i-feather>
                  <a href="javascript:void(0)" class="text-dark ml-2">Harry</a> on <a href="javascript:void(0)"
                    class="text-primary">Customized template options</a>
                </li>
              </ul>
            </div>
            <!-- RECENT POST -->

            <!-- SOCIAL -->
            <div class="widget">
              <h4 class="widget-title">Follow us</h4>
              <ul class="list-unstyled social-icon social mb-0 mt-4">
                <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="github" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="youtube" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
              </ul>
              <!--end icon-->
            </div>
            <!-- SOCIAL -->
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Start Forums -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
