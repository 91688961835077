 <!-- Hero Start -->
 <section class="bg-half-170 d-table w-100" id="home"
   style="background: url('assets/images/travel/bg.jpg') center center;">
   <div class="bg-overlay"></div>
   <div class="container">
     <div class="row align-items-center">
       <div class="col-lg-7 col-md-6">
         <div class="title-heading mt-4">
           <h1 class="display-4 font-weight-bold text-white title-dark mb-3">Booking tour <br> made easy</h1>
           <p class="para-desc text-white-50">Launch your campaign and benefit from our expertise on designing and
             managing conversion centered bootstrap4 html page.</p>
           <div class="mt-4">
             <a href="javascript:void(0)" class="btn btn-icon btn-pills btn-lg btn-light">
               <i-feather name="arrow-down" class="icons"></i-feather>
             </a>
           </div>
         </div>
       </div>
       <!--end col-->

       <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
         <div class="card login_page shadow rounded border-0 ml-lg-5">
           <div class="card-body">
             <h5 class="card-title">You can start search here</h5>

             <form class="login-form">
               <div class="row">
                 <div class="col-md-12">
                   <div class="form-group position-relative">
                     <label>Destination</label>
                     <i-feather name="map-pin" class="fea icon-sm icons"></i-feather>
                     <input type="text" class="form-control pl-5" placeholder="Whare" name="s" required="">
                   </div>
                 </div>
                 <div class="col-md-6">
                   <div class="form-group">
                     <label> Check in : </label>
                     <input type="text" class="form-control" name="picker" id="checkin" [(ngModel)]="checkin"
                       mwlFlatpickr [convertModelValue]="true">
                   </div>
                 </div>
                 <!--end col-->

                 <div class="col-md-6">
                   <div class="form-group">
                     <label> Check out : </label>
                     <input type="text" class="form-control" name="checkoutpicker" id="checkout" [(ngModel)]="checkout"
                       mwlFlatpickr [convertModelValue]="true">
                   </div>
                 </div>
                 <!--end col-->
                 <div class="col-md-12">
                   <div class="form-group position-relative">
                     <label>Your Email</label>
                     <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                     <input type="email" class="form-control pl-5" placeholder="Email" name="email" required="">
                   </div>
                 </div>
                 <div class="col-md-12">
                   <div class="form-group">
                     <div class="custom-control custom-checkbox">
                       <input type="checkbox" class="custom-control-input" id="customCheck1">
                       <label class="custom-control-label" for="customCheck1">I Accept <a href="#"
                           class="text-primary">Terms And Condition</a></label>
                     </div>
                   </div>
                 </div>
                 <div class="col-md-12">
                   <button class="btn btn-success w-100">Search Now</button>
                 </div>
               </div>
             </form>
           </div>
         </div>
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->
 </section>
 <!--end section-->
 <div class="position-relative">
   <div class="shape overflow-hidden text-white">
     <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
       <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
     </svg>
   </div>
 </div>
 <!-- Hero End -->

 <section class="section">
   <div class="container">
     <div class="row justify-content-center">
       <div class="col-12 text-center">
         <div class="section-title mb-4 pb-2">
           <h4 class="title mb-4">Benefit for Traveller</h4>
           <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
               class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
             awareness, drive traffic, connect.</p>
         </div>
       </div>
       <!--end col-->
     </div>
     <!--end row-->

     <div class="row">
       <div class="col-lg-3 col-md-4 mt-4 pt-2">
         <div
           class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
           <span class="h1 icon2 text-primary">
             <i class="uil uil-shield-check"></i>
           </span>
           <div class="card-body p-0 content">
             <h5>Secure Payment</h5>
             <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
           </div>
           <span class="big-icon text-center">
             <i class="uil uil-shield-check"></i>
           </span>
         </div>
       </div>
       <!--end col-->

       <div class="col-lg-3 col-md-4 mt-4 pt-2">
         <div
           class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
           <span class="h1 icon2 text-primary">
             <i class="uil uil-thumbs-up"></i>
           </span>
           <div class="card-body p-0 content">
             <h5>Easy Book</h5>
             <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
           </div>
           <span class="big-icon text-center">
             <i class="uil uil-thumbs-up"></i>
           </span>
         </div>
       </div>
       <!--end col-->

       <div class="col-lg-3 col-md-4 mt-4 pt-2">
         <div
           class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
           <span class="h1 icon2 text-primary">
             <i class="uil uil-keyboard-show"></i>
           </span>
           <div class="card-body p-0 content">
             <h5>Free Amenities</h5>
             <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
           </div>
           <span class="big-icon text-center">
             <i class="uil uil-keyboard-show"></i>
           </span>
         </div>
       </div>
       <!--end col-->

       <div class="col-lg-3 col-md-4 mt-4 pt-2">
         <div
           class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
           <span class="h1 icon2 text-primary">
             <i class="uil uil-award"></i>
           </span>
           <div class="card-body p-0 content">
             <h5>Best Offers</h5>
             <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
           </div>
           <span class="big-icon text-center">
             <i class="uil uil-award"></i>
           </span>
         </div>
       </div>
       <!--end col-->

       <div class="col-lg-3 col-md-4 mt-4 pt-2">
         <div
           class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
           <span class="h1 icon2 text-primary">
             <i class="uil uil-bookmark"></i>
           </span>
           <div class="card-body p-0 content">
             <h5>Cheap than Other</h5>
             <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
           </div>
           <span class="big-icon text-center">
             <i class="uil uil-bookmark"></i>
           </span>
         </div>
       </div>
       <!--end col-->

       <div class="col-lg-3 col-md-4 mt-4 pt-2">
         <div
           class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
           <span class="h1 icon2 text-primary">
             <i class="uil uil-favorite"></i>
           </span>
           <div class="card-body p-0 content">
             <h5>Top Rated</h5>
             <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
           </div>
           <span class="big-icon text-center">
             <i class="uil uil-favorite"></i>
           </span>
         </div>
       </div>
       <!--end col-->

       <div class="col-lg-3 col-md-4 mt-4 pt-2">
         <div
           class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
           <span class="h1 icon2 text-primary">
             <i class="uil uil-clock"></i>
           </span>
           <div class="card-body p-0 content">
             <h5>24/7 Support</h5>
             <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
           </div>
           <span class="big-icon text-center">
             <i class="uil uil-clock"></i>
           </span>
         </div>
       </div>
       <!--end col-->

       <div class="col-lg-3 col-md-4 mt-4 pt-2">
         <div
           class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
           <span class="h1 icon2 text-primary">
             <i class="uil uil-process"></i>
           </span>
           <div class="card-body p-0 content">
             <h5>Fast Refund</h5>
             <p class="para text-muted mb-0">It is a long established fact that a reader.</p>
           </div>
           <span class="big-icon text-center">
             <i class="uil uil-process"></i>
           </span>
         </div>
       </div>
       <!--end col-->

       <div class="col-lg-12 text-center col-md-4 mt-4 pt-2">
         <a href="javascript:void(0)" class="btn btn-primary">See more
           <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
         </a>
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->

   <div class="container mt-100 mt-60">
     <div class="row align-items-end mb-4 pb-2">
       <div class="col-md-8">
         <div class="section-title text-center text-md-left">
           <h6 class="text-primary">Find Out Most</h6>
           <h4 class="title mb-4">Popular Destination</h4>
           <p class="text-muted mb-0 para-desc">Start working with <span
               class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
             awareness, drive traffic, connect.</p>
         </div>
       </div>
       <!--end col-->

       <div class="col-md-4 mt-4 mt-sm-0">
         <div class="text-center text-md-right">
           <a href="javascript:void(0)" class="text-primary h6">See More
             <i-feather name="arrow-right" class="fea icon-sm"></i-feather>
           </a>
         </div>
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->
   <div class="container-fluid">
     <div class="row">
       <div class="col-md-12 mt-4 pt-2">
         <owl-carousel-o id="six-slide" [options]="customOptions" class="owl-stage-outer">
           <ng-template carouselSlide>
             <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
               <img src="assets/images/travel/dubai.jpg" class="img-fluid" alt="">
               <div class="overlay-work bg-dark"></div>
               <div class="content">
                 <a href="javascript:void(0)" class="title text-white h4 title-dark">Dubai</a>
               </div>
             </div>
             <!--end tour post-->
           </ng-template>
           <ng-template carouselSlide>
             <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
               <img src="assets/images/travel/thailand.jpg" class="img-fluid" alt="">
               <div class="overlay-work bg-dark"></div>
               <div class="content">
                 <a href="javascript:void(0)" class="title text-white h4 title-dark">Thailand</a>
               </div>
             </div>
             <!--end tour post-->
           </ng-template>
           <ng-template carouselSlide>
             <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
               <img src="assets/images/travel/india.jpg" class="img-fluid" alt="">
               <div class="overlay-work bg-dark"></div>
               <div class="content">
                 <a href="javascript:void(0)" class="title text-white h4 title-dark">India</a>
               </div>
             </div>
             <!--end tour post-->
           </ng-template>
           <ng-template carouselSlide>
             <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
               <img src="assets/images/travel/london.jpg" class="img-fluid" alt="">
               <div class="overlay-work bg-dark"></div>
               <div class="content">
                 <a href="javascript:void(0)" class="title text-white h4 title-dark">Londan</a>
               </div>
             </div>
             <!--end tour post-->
           </ng-template>
           <ng-template carouselSlide>
             <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
               <img src="assets/images/travel/paris.jpg" class="img-fluid" alt="">
               <div class="overlay-work bg-dark"></div>
               <div class="content">
                 <a href="javascript:void(0)" class="title text-white h4 title-dark">Paris</a>
               </div>
             </div>
             <!--end tour post-->
           </ng-template>
           <ng-template carouselSlide>
             <div class="popular-tour rounded-md position-relative overflow-hidden mx-3">
               <img src="assets/images/travel/singapore.jpg" class="img-fluid" alt="">
               <div class="overlay-work bg-dark"></div>
               <div class="content">
                 <a href="javascript:void(0)" class="title text-white h4 title-dark">Singapore</a>
               </div>
             </div>
             <!--end tour post-->
           </ng-template>
         </owl-carousel-o>
         <!-- </div> -->
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
 </section>
 <!--end section-->

 <section class="section bg-light">
   <div class="container">
     <div class="row justify-content-center">
       <div class="col-12 text-center">
         <div class="section-title">
           <h4 class="title font-weight-bold mb-4">Everyone Needs Travel. <br> Want to Break Free for a While</h4>
           <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
               class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
             awareness, drive traffic, connect.</p>
         </div>
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->

   <div class="container mt-100 mt-60">
     <div class="row align-items-center">
       <div class="col-lg-5 col-md-6">
         <div class="card blog rounded border-0 shadow overflow-hidden">
           <div class="position-relative">
             <img src="assets/images/travel/1.jpg" class="card-img-top" alt="...">
             <div class="overlay rounded-top bg-dark"></div>
           </div>
           <div class="card-body content">
             <h5><a href="javascript:void(0)" class="card-title title text-dark">Conversations, Feedback,
                 Recognition</a></h5>
             <div class="post-meta d-flex justify-content-between mt-3">
               <ul class="list-unstyled mb-0">
                 <li class="list-inline-item mr-2 mb-0"><a href="javascript:void(0)" class="text-muted like"><i
                       class="mdi mdi-heart-outline mr-1"></i>33</a></li>
                 <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted comments"><i
                       class="mdi mdi-comment-outline mr-1"></i>08</a></li>
               </ul>
               <a routerLink="/page-blog-detail" class="text-muted readmore">Read More <i
                   class="mdi mdi-chevron-right"></i></a>
             </div>
           </div>
           <div class="author">
             <small class="text-light user d-block"><i class="mdi mdi-account"></i> Calvin Carlo</small>
             <small class="text-light date"><i class="mdi mdi-calendar-check"></i> 13th August, 2019</small>
           </div>
         </div>
       </div>
       <!--end col-->

       <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
         <div class="section-title ml-lg-4">
           <h4 class="title mb-4">Experience Luxury & <br> Find Your Base</h4>
           <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
             from the Application theme and use it in the Website.</p>
           <ul class="list-unstyled text-muted">
             <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Digital
               Marketing Solutions for Tomorrow</li>
             <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Our Talented
               & Experienced Marketing Agency</li>
             <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Create your
               own skin to match your brand</li>
           </ul>
           <a href="javascript:void(0)" class="mt-3 h6 text-primary">Search Destination <i
               class="mdi mdi-chevron-right"></i></a>
         </div>
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->

   <div class="container mt-100 mt-60">
     <div class="row align-items-center">
       <div class="col-lg-5 col-md-6 order-1 order-md-2">
         <img src="assets/images/travel/3.jpg" class="img-fluid rounded shadow-md" alt="">
       </div>
       <!--end col-->

       <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
         <div class="section-title mr-lg-5">
           <h4 class="title mb-4">100% Money back <br> Guarantee if You Cancel</h4>
           <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
             from the Application theme and use it in the Website.</p>
           <div class="faq-content mt-4 pt-2">
             <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0" closeOthers="true">
               <ngb-panel cardClass="border-0 rounded shadow mb-2">
                 <ng-template ngbPanelTitle>
                   <div class="border-0 bg-light" id="headingtwo">
                     <h6 class="title mb-0 text-dark"> How our <span class="text-primary">Landrick</span> work ?
                     </h6>
                   </div>
                 </ng-template>
                 <ng-template ngbPanelContent>
                   <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                     but
                     the majority have suffered alteration in some form.</p>
                 </ng-template>
               </ngb-panel>
               <ngb-panel cardClass="border-0 rounded shadow mb-2">
                 <ng-template ngbPanelTitle>
                   <div class="border-0 bg-light" id="headingtwo">
                     <h6 class="title mb-0 text-dark"> What is the main process open account ? </h6>
                   </div>
                 </ng-template>
                 <ng-template ngbPanelContent>
                   <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                     but
                     the majority have suffered alteration in some form.</p>
                 </ng-template>
               </ngb-panel>
               <ngb-panel cardClass="border-0 rounded shadow mb-2">
                 <ng-template ngbPanelTitle>
                   <div class="border-0 bg-light" id="headingtwo">
                     <h6 class="title mb-0 text-dark"> How to make unlimited data entry ? </h6>
                   </div>
                 </ng-template>
                 <ng-template ngbPanelContent>
                   <p class="text-muted mb-0 faq-ans">There are many variations of passages of Lorem Ipsum available,
                     but
                     the majority have suffered alteration in some form.</p>
                 </ng-template>
               </ngb-panel>
             </ngb-accordion>

           </div>
         </div>
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->

   <div class="container mt-100 mt-60">
     <div class="row align-items-center">
       <div class="col-lg-5 col-md-6">
         <img src="assets/images/travel/2.jpg" class="img-fluid rounded shadow-md" alt="">
       </div>
       <!--end col-->

       <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
         <div class="section-title ml-lg-4">
           <h4 class="title mb-4">We have More than 500 <br> Selected Destination</h4>
           <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
             from the Application theme and use it in the Website.</p>
           <ul class="list-unstyled text-muted">
             <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Digital
               Marketing Solutions for Tomorrow</li>
             <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Our Talented
               & Experienced Marketing Agency</li>
             <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Create your
               own skin to match your brand</li>
           </ul>
           <a href="javascript:void(0)" class="mt-3 h6 text-primary">Search Destination <i
               class="mdi mdi-chevron-right"></i></a>
         </div>
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->

   <div class="container mt-100 mt-60">
     <div class="row justify-content-center">
       <div class="col-12 text-center">
         <div class="section-title mb-4 pb-2">
           <h4 class="title mb-4">Whats Our Clients Said About <span class="text-primary">Landrick</span> Project</h4>
           <p class="text-muted para-desc mx-auto mb-0">Start working with <span
               class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
             awareness, drive traffic, connect.</p>
         </div>
       </div>
       <!--end col-->
     </div>
     <!--end row-->

     <div class="row justify-content-center">
       <div class="col-lg-12 mt-4">
         <owl-carousel-o id="customer-testi" [options]="testiOptions">
           <ng-template carouselSlide>
             <div class="media customer-testi m-2">
               <img src="assets/images/client/01.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
               <div class="media-body content p-3 shadow rounded bg-white position-relative">
                 <ul class="list-unstyled mb-0">
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                 </ul>
                 <p class="text-muted mt-2">" It seems that only fragments of the original text remain in the Lorem
                   Ipsum texts used today. "</p>
                 <h6 class="text-primary">- Thomas Israel <small class="text-muted">C.E.O</small></h6>
               </div>
             </div>
           </ng-template>
           <ng-template carouselSlide>
             <div class="media customer-testi m-2">
               <img src="assets/images/client/02.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
               <div class="media-body content p-3 shadow rounded bg-white position-relative">
                 <ul class="list-unstyled mb-0">
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star-half text-warning"></i></li>
                 </ul>
                 <p class="text-muted mt-2">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear
                   more frequently than others. "</p>
                 <h6 class="text-primary">- Barbara McIntosh <small class="text-muted">M.D</small></h6>
               </div>
             </div>
           </ng-template>
           <ng-template carouselSlide>
             <div class="media customer-testi m-2">
               <img src="assets/images/client/03.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
               <div class="media-body content p-3 shadow rounded bg-white position-relative">
                 <ul class="list-unstyled mb-0">
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                 </ul>
                 <p class="text-muted mt-2">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have
                   originated in the 16th century. "</p>
                 <h6 class="text-primary">- Carl Oliver <small class="text-muted">P.A</small></h6>
               </div>
             </div>
           </ng-template>
           <ng-template carouselSlide>
             <div class="media customer-testi m-2">
               <img src="assets/images/client/04.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
               <div class="media-body content p-3 shadow rounded bg-white position-relative">
                 <ul class="list-unstyled mb-0">
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                 </ul>
                 <p class="text-muted mt-2">" According to most sources, Lorum Ipsum can be traced back to a text
                   composed by Cicero. "</p>
                 <h6 class="text-primary">- Christa Smith <small class="text-muted">Manager</small></h6>
               </div>
             </div>
           </ng-template>
           <ng-template carouselSlide>
             <div class="media customer-testi m-2">
               <img src="assets/images/client/05.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
               <div class="media-body content p-3 shadow rounded bg-white position-relative">
                 <ul class="list-unstyled mb-0">
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                 </ul>
                 <p class="text-muted mt-2">" There is now an abundance of readable dummy texts. These are usually used
                   when a text is required. "</p>
                 <h6 class="text-primary">- Dean Tolle <small class="text-muted">Developer</small></h6>
               </div>
             </div>
           </ng-template>
           <ng-template carouselSlide>
             <div class="media customer-testi m-2">
               <img src="assets/images/client/06.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
               <div class="media-body content p-3 shadow rounded bg-white position-relative">
                 <ul class="list-unstyled mb-0">
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                   <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                 </ul>
                 <p class="text-muted mt-2">" Thus, Lorem Ipsum has only limited suitability as a visual filler for
                   German texts. "</p>
                 <h6 class="text-primary">- Jill Webb <small class="text-muted">Designer</small></h6>
               </div>
             </div>
           </ng-template>
         </owl-carousel-o>
         <!-- </div> -->
       </div>
       <!--end col-->
     </div>
     <!--end row-->
   </div>
   <!--end container-->
 </section>
 <!--end section-->

 <!-- Back to top -->
 <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
   [ngxScrollTo]="'#home'">
   <i-feather name="arrow-up" class="icons"></i-feather>
 </a>
 <!-- Back to top -->
