import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-helpcenter-overview',
  templateUrl: './helpcenter-overview.component.html',
  styleUrls: ['./helpcenter-overview.component.css']
})
export class HelpcenterOverviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
