import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-account-payments',
  templateUrl: './account-payments.component.html',
  styleUrls: ['./account-payments.component.css']
})
export class AccountPaymentsComponent implements OnInit {
  navClass = 'nav-light';
  constructor() { }

  ngOnInit(): void {
  }


}
