import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-helpcenter-guides',
  templateUrl: './helpcenter-guides.component.html',
  styleUrls: ['./helpcenter-guides.component.css']
})
export class HelpcenterGuidesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }



}
