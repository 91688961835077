import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-blog-detail',
  templateUrl: './page-blog-detail.component.html',
  styleUrls: ['./page-blog-detail.component.css']
})
export class PageBlogDetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }


}
