<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background: url('assets/images/3.jpg') center center;" id="home">
  <div class="container">
    <div class="row position-relative align-items-center pt-4">
      <div class="col-lg-7 offset-lg-5">
        <div class="title-heading studio-home rounded bg-white shadow mt-5">
          <h1 class="heading mb-3">Present Your Work With <span class="text-primary">Landrick</span> Studio</h1>
          <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap4 html page.</p>
          <div class="mt-4">
            <a routerLink="/page-contact-one" class="btn btn-primary mt-2 mr-2"><i class="mdi mdi-phone"></i> Get
              Started</a>
            <a routerLink="/documentation" class="btn btn-outline-primary mt-2"><i class="mdi mdi-book-outline"></i>
              Documentation</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Partners start -->
<section class="py-4 bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-2 col-md-2 col-6 text-center py-4">
        <img src="assets/images/client/amazon.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center py-4">
        <img src="assets/images/client/google.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center py-4">
        <img src="assets/images/client/lenovo.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center py-4">
        <img src="assets/images/client/paypal.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center py-4">
        <img src="assets/images/client/shopify.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-2 col-md-2 col-6 text-center py-4">
        <img src="assets/images/client/spotify.svg" class="avatar avatar-ex-sm" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Partners End -->

<!-- Work Process Start -->
<section class="section">
  <!--(.work-process) css write in (_feature.scss)-->
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Work Process</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-4 mt-4 pt-2">
        <div class="card work-process border-0 rounded shadow">
          <div class="card-body">
            <h4 class="title">Discuss The Project</h4>
            <p class="text-muted para">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
              originated in the 16th century.</p>
            <a href="javascript:void(0)" class="text-primary">Read more <i class="mdi mdi-chevron-right"></i></a>
            <ul class="list-unstyled d-flex justify-content-between mb-0 mt-2">
              <li class="step h1 mb-0 font-weight-bold">Step 01.</li>
              <li class="step-icon"><i class="mdi mdi-chevron-double-right mdi-36px"></i></li>
            </ul>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 pt-2">
        <div class="card work-process border-0 rounded shadow">
          <div class="card-body">
            <h4 class="title">Develop & Elaborate</h4>
            <p class="text-muted para">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
              originated in the 16th century.</p>
            <a href="javascript:void(0)" class="text-primary">Read more <i class="mdi mdi-chevron-right"></i></a>
            <ul class="list-unstyled d-flex justify-content-between mb-0 mt-2">
              <li class="step h1 mb-0 font-weight-bold">Step 02.</li>
              <li class="step-icon"><i class="mdi mdi-chevron-double-right mdi-36px"></i></li>
            </ul>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 mt-4 pt-2">
        <div class="card work-process border-0 rounded shadow">
          <div class="card-body">
            <h4 class="title">Final Approvement</h4>
            <p class="text-muted para">The most well-known dummy text is the 'Lorem Ipsum', which is said to have
              originated in the 16th century.</p>
            <a href="javascript:void(0)" class="text-primary">Read more <i class="mdi mdi-chevron-right"></i></a>
            <ul class="list-unstyled d-flex justify-content-between mb-0 mt-2">
              <li class="step h1 mb-0 font-weight-bold">Step 03.</li>
              <li class="step-icon"><i class="mdi mdi-check-all mdi-36px"></i></li>
            </ul>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 pt-50">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Latest Projects</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="card border-0 work-container work-classic">
          <div class="card-body p-0">
            <a routerLink="/page-work-detail"><img src="assets/images/work/1.jpg" class="img-fluid rounded work-image"
                alt=""></a>
            <div class="content pt-3">
              <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Iphone mockup</a></h5>
              <h6 class="text-muted tag mb-0">Branding</h6>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="card border-0 work-container work-classic">
          <div class="card-body p-0">
            <a routerLink="/page-work-detail"><img src="assets/images/work/2.jpg" class="img-fluid rounded work-image"
                alt=""></a>
            <div class="content pt-3">
              <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Mockup Collection</a></h5>
              <h6 class="text-muted tag mb-0">Mockup</h6>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="card border-0 work-container work-classic">
          <div class="card-body p-0">
            <a routerLink="/page-work-detail"><img src="assets/images/work/3.jpg" class="img-fluid rounded work-image"
                alt=""></a>
            <div class="content pt-3">
              <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Abstract images</a></h5>
              <h6 class="text-muted tag mb-0">Abstract</h6>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="card border-0 work-container work-classic">
          <div class="card-body p-0">
            <a routerLink="/page-work-detail"><img src="assets/images/work/4.jpg" class="img-fluid rounded work-image"
                alt=""></a>
            <div class="content pt-3">
              <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Yellow bg with Books</a></h5>
              <h6 class="text-muted tag mb-0">Books</h6>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="card border-0 work-container work-classic">
          <div class="card-body p-0">
            <a routerLink="/page-work-detail"><img src="assets/images/work/5.jpg" class="img-fluid rounded work-image"
                alt=""></a>
            <div class="content pt-3">
              <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Company V-card</a></h5>
              <h6 class="text-muted tag mb-0">V-card</h6>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-6 col-12 mt-4 pt-2">
        <div class="card border-0 work-container work-classic">
          <div class="card-body p-0">
            <a routerLink="/page-work-detail"><img src="assets/images/work/6.jpg" class="img-fluid rounded work-image"
                alt=""></a>
            <div class="content pt-3">
              <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Mockup box with paints</a></h5>
              <h6 class="text-muted tag mb-0">Photography</h6>
            </div>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-12 text-center mt-4 pt-2">
        <a routerLink="/page-work-modern" class="btn btn-primary">See More <i class="mdi mdi-arrow-right"></i></a>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">See everything about your workplace</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>

          <div class="mt-3">
            <a href="#" target="_blank" class="btn btn-primary mt-2 mr-2">Buy Now <span
                class="badge badge-pill badge-danger ml-2">v2.5</span></a>
            <a href="javascript:void(0)" data-toggle="modal" data-target="#LoginForm"
              class="btn btn-outline-primary mt-2"><i class="mdi mdi-account-check-outline"></i> Free Trial</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Work Process End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->

<!-- Login Modal start -->
<div class="modal fade" id="LoginForm" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content rounded shadow border-0">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalCenterTitle">15 Days Free Trail</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="bg-white p-3 rounded box-shadow">
          <form>
            <div class="row">
              <div class="col-lg-12">
                <div class="form-group position-relative">
                  <label>Name : <span class="text-danger">*</span></label>
                  <i-feather name="user" class="fea icon-sm icons"></i-feather>
                  <input type="text" class="form-control pl-5" placeholder="Name" name="name" required="">
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-12">
                <div class="form-group position-relative">
                  <label>Email : <span class="text-danger">*</span></label>
                  <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                  <input type="email" class="form-control pl-5" placeholder="Email" name="email" required="">
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-12">
                <div class="form-group position-relative">
                  <label>Password : <span class="text-danger">*</span></label>
                  <i-feather name="key" class="fea icon-sm icons"></i-feather>
                  <input type="password" class="form-control pl-5" placeholder="Password" required="">
                </div>
              </div>
              <!--end col-->

              <div class="col-lg-12 mt-2 mb-0">
                <button class="btn btn-primary btn-block">Login</button>
              </div>
              <!--end col-->
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Login Modal end -->
