<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-12 text-center">
        <div class="page-next-level">
          <h4 class="title"> Branded T-Shirts </h4>
          <div class="page-next">
            <nav aria-label="breadcrumb" class="d-inline-block">
              <ul class="breadcrumb bg-white rounded shadow mb-0">
                <li class="breadcrumb-item"><a routerLink="/index">Landrick</a></li>
                <li class="breadcrumb-item"><a routerLink="/index-shop">Shop</a></li>
                <li class="breadcrumb-item active" aria-current="page">Product Details</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<section class="section">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-md-5">

        <div class="slider slider-for">
          <div><img src="assets/images/shop/product/single-1.jpg" id="expandedImg" class="img-fluid rounded" alt="">
          </div>
        </div>
        <div class="slider slider-nav mt-2 d-flex">
          <div><img src="assets/images/shop/product/single-1.jpg" (click)="imageShow($event)" class="img-fluid" alt=""
              style="width: 150px;">
          </div>
          <div><img src="assets/images/shop/product/single-5.jpg" (click)="imageShow($event)" class="img-fluid" alt=""
              style="width: 150px;">
          </div>
          <div><img src="assets/images/shop/product/single-3.jpg" (click)="imageShow($event)" class="img-fluid" alt=""
              style="width: 150px;">
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-7 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ml-md-4">
          <h4 class="title">Branded T-Shirts</h4>
          <h5 class="text-muted">$21.00 <del class="text-danger ml-2">$25.00</del> </h5>
          <ul class="list-unstyled text-warning h5 mb-0">
            <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
            <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
          </ul>

          <h5 class="mt-4 py-2">Overview :</h5>
          <p class="text-muted">Lorem ipsum dolor sit amet consectetur adipisicing elit. Vero exercitationem, unde
            molestiae sint quae inventore atque minima natus fugiat nihil quisquam voluptates ea omnis. Modi laborum
            soluta tempore unde accusantium.</p>

          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span> Digital
              Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span> Our Talented
              &amp; Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span> Create your
              own skin to match your brand</li>
          </ul>

          <div class="row mt-4 pt-2">
            <div class="col-lg-6 col-12">
              <div class="d-flex align-items-center">
                <h6 class="mb-0">Your Size:</h6>
                <ul class="list-unstyled mb-0 ml-3">
                  <li class="list-inline-item"><a href="javascript:void(0)" class="btn btn-icon btn-soft-primary">S</a>
                  </li>
                  <li class="list-inline-item ml-1"><a href="javascript:void(0)"
                      class="btn btn-icon btn-soft-primary">M</a></li>
                  <li class="list-inline-item ml-1"><a href="javascript:void(0)"
                      class="btn btn-icon btn-soft-primary">L</a></li>
                  <li class="list-inline-item ml-1"><a href="javascript:void(0)"
                      class="btn btn-icon btn-soft-primary">XL</a></li>
                </ul>
              </div>
            </div>
            <!--end col-->

            <div class="col-lg-6 col-12 mt-4 mt-lg-0">
              <div class="d-flex shop-list align-items-center">
                <h6 class="mb-0">Quantity:</h6>
                <div class="ml-3">
                  <input type="button" value="-" class="minus btn btn-icon btn-soft-primary font-weight-bold"
                    (click)="decrement()">
                  <input type="text" step="1" min="1" name="quantity" value="1" title="Qty" [(ngModel)]="model"
                    class="btn btn-icon btn-soft-primary font-weight-bold ml-1">
                  <input type="button" value="+" class="plus btn btn-icon btn-soft-primary font-weight-bold ml-1"
                    (click)="increase()">
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="mt-4 pt-2">
            <a href="javascript:void(0)" class="btn btn-primary">Shop Now</a>
            <a href="shop-cart" class="btn btn-soft-primary ml-2">Add to Cart</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row">
      <div class="col-12">
        <h5 class="mb-0">Related Products</h5>
      </div>
      <!--end col-->

      <div class="col-12 mt-4">
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="card shop-list border-0 position-relative overflow-hidden m-2">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s1.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-1.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Branded T-Shirt</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$16.00 <del class="text-danger ml-2">$21.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card shop-list border-0 position-relative overflow-hidden m-2">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s3.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-3.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Elegent Watch</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$5.00 <span class="text-success ml-1">30%
                      off</span> </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card shop-list border-0 position-relative overflow-hidden m-2">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s6.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-6.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Elegent Mug</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$4.50 <del class="text-danger ml-2">$6.50</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card shop-list border-0 position-relative overflow-hidden m-2">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s8.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-8.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Wooden Stools</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$22.00 <del class="text-danger ml-2">$25.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="card shop-list border-0 position-relative overflow-hidden m-2">
              <div class="shop-image position-relative overflow-hidden rounded shadow">
                <a routerLink="/shop-product-detail"><img src="assets/images/shop/product/s14.jpg" class="img-fluid"
                    alt=""></a>
                <a routerLink="/shop-product-detail" class="overlay-work">
                  <img src="assets/images/shop/product/s-14.jpg" class="img-fluid" alt="">
                </a>
                <ul class="list-unstyled shop-icons">
                  <li><a href="javascript:void(0)" class="btn btn-icon btn-pills btn-soft-danger">
                      <i-feather name="heart" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-product-detail" class="btn btn-icon btn-pills btn-soft-primary">
                      <i-feather name="eye" class="icons"></i-feather>
                    </a></li>
                  <li class="mt-2"><a routerLink="/shop-cart" class="btn btn-icon btn-pills btn-soft-warning">
                      <i-feather name="shopping-cart" class="icons"></i-feather>
                    </a></li>
                </ul>
              </div>
              <div class="card-body content pt-4 p-2">
                <a routerLink="/shop-product-detail" class="text-dark product-name h6">Women Block Heels</a>
                <div class="d-flex justify-content-between mt-1">
                  <h6 class="text-muted small font-italic mb-0 mt-1">$21.00 <del class="text-danger ml-2">$25.00</del>
                  </h6>
                  <ul class="list-unstyled text-warning mb-0">
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                    <li class="list-inline-item"><i class="mdi mdi-star"></i></li>
                  </ul>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
        <!-- </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
