<!-- Hero Start -->
<section class="bg-half-260 bg-primary d-table w-100" id="home"
  style="background: url('assets/images/software/bg.png') center center;">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row align-items-center position-relative mt-5" style="z-index: 1;">
      <div class="col-lg-6 col-md-12">
        <div class="title-heading mt-4 text-center text-lg-left">
          <h1 class="heading mb-3 title-dark text-white">Best Software For Your Work Monitor</h1>
          <p class="para-desc text-white-50">Launch your campaign and benefit from our expertise on designing and
            managing conversion centered bootstrap4 html page.</p>
          <div class="mt-4">
            <a href="javascript:void(0)" class="btn btn-light"><i class="mdi mdi-download"></i> Download Now</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-12 mt-4 pt-2">
        <div class="position-relative">
          <div class="software_hero">
            <img src="assets/images/software/software.png" class="img-fluid d-block" alt="">
          </div>
          <div class="play-icon">
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" class="play-btn video-play-icon">
              <i class="mdi mdi-play text-primary rounded-circle bg-white shadow"></i>
            </a>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/287684225" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Section Start -->
<section class="section pt-md-4 pt-5">
  <!-- Start Features -->
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Solutions</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-md-4 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/pen.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Seo Services</h4>
            <p class="text-muted mb-0">Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam aenean
              elementum semper.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/video.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Email Marketing</h4>
            <p class="text-muted mb-0">Allegedly, a Latin scholar established the origin of the text by established
              compiling unusual word.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/intellectual.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Data Analysis</h4>
            <p class="text-muted mb-0">It seems that only fragments of the original text remain in the Lorem Ipsum
              fragments texts used today.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/user.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Digital Marketing</h4>
            <p class="text-muted mb-0">Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam aenean
              elementum semper.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/calendar.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Social Media Marketing</h4>
            <p class="text-muted mb-0">Allegedly, a Latin scholar established the origin of the text by compiling
              unusual established word.</p>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-md-4 col-12 mt-5">
        <div class="features text-center">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/sand-clock.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Link Building</h4>
            <p class="text-muted mb-0">It seems that only fragments of the original text remain in the Lorem Ipsum texts
              used fragments today.</p>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center mt-5 pt-4">
      <div class="col-lg-10 col-md-12">
        <img src="assets/images/software/mobile-hori.png" class="img-fluid d-block mx-auto" alt="">
      </div>
      <!--end col-->
      <div class="col-12 text-center pt-2">
        <div class="alert alert-light alert-pills shadow" role="alert">
          <span class="badge badge-pill badge-primary mr-1">Download</span>
          <span class="content"> Trusted by the world's best <i class="mdi mdi-download"></i></span>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- End Features -->

  <div class="container mt-100 mt-60">
    <div class="card rounded bg-light shadow border-0">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col-sm-7">
            <div class="text-sm-left">
              <h5 class="mb-0">Start building beautiful block-based websites.</h5>
            </div>
          </div>
          <!--end col-->

          <div class="col-sm-5 mt-4 mt-sm-0">
            <div class="text-sm-right">
              <a href="javascript:void(0)" class="btn btn-outline-primary"><i class="mdi mdi-file-document-outline"></i>
                Start Now </a>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row -->
      </div>
    </div>
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <img src="assets/images/software/mobile01.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ml-lg-5">
          <h4 class="title mb-4">Carry out Marketing Initiatives : Landrick</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled mb-0 text-muted">
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Digital
              Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Our Talented
              & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Create your
              own skin to match your brand</li>
          </ul>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <!-- Price Start -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Chose your perfect Plan</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
        <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
          <div class="card-body">
            <h2 class="title text-uppercase mb-4">Free</h2>
            <div class="d-flex mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">0</span>
              <span class="h4 align-self-end mb-1">/mo</span>
            </div>

            <ul class="list-unstyled mb-0 pl-0">
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Full Access</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Source Files</li>
            </ul>
            <a href="javascript:void(0)" class="btn btn-primary mt-4">Buy Now</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
        <div class="card pricing-rates business-rate shadow bg-white border-0 rounded">
          <div class="card-body">
            <h2 class="title text-uppercase text-primary mb-4">Starter</h2>
            <div class="d-flex mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">39</span>
              <span class="h4 align-self-end mb-1">/mo</span>
            </div>

            <ul class="list-unstyled mb-0 pl-0">
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Full Access</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Source Files</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Free Appointments</li>
            </ul>
            <a href="javascript:void(0)" class="btn btn-primary mt-4">Get Started</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
        <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
          <div class="card-body">
            <h2 class="title text-uppercase mb-4">Professional</h2>
            <div class="d-flex mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">59</span>
              <span class="h4 align-self-end mb-1">/mo</span>
            </div>

            <ul class="list-unstyled mb-0 pl-0">
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Full Access</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Source Files</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>1 Domain Free</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Enhanced Security</li>
            </ul>
            <a href="javascript:void(0)" class="btn btn-primary mt-4">Try It Now</a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
        <div class="card pricing-rates business-rate shadow bg-light border-0 rounded">
          <div class="card-body">
            <h2 class="title text-uppercase mb-4">Ultimate</h2>
            <div class="d-flex mb-4">
              <span class="h4 mb-0 mt-2">$</span>
              <span class="price h1 mb-0">79</span>
              <span class="h4 align-self-end mb-1">/mo</span>
            </div>

            <ul class="list-unstyled mb-0 pl-0">
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Full Access</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Enhanced Security</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Source Files</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>1 Domain Free</li>
              <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                    class="uim uim-check-circle"></i></span>Free Installment</li>
            </ul>
            <a href="javascript:void(0)" class="btn btn-primary mt-4">Started Now</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- Price End -->

  <!-- Start Testimonial -->
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Client Reviews</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <!-- <div id="customer-testi" class="owl-carousel owl-theme"> -->
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/01.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" It seems that only fragments of the original text remain in the Lorem Ipsum
                  texts used today. "</p>
                <h6 class="text-primary">- Thomas Israel <small class="text-muted">C.E.O</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/02.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star-half text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear
                  more frequently than others. "</p>
                <h6 class="text-primary">- Barbara McIntosh <small class="text-muted">M.D</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/03.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have
                  originated in the 16th century. "</p>
                <h6 class="text-primary">- Carl Oliver <small class="text-muted">P.A</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/04.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" According to most sources, Lorum Ipsum can be traced back to a text
                  composed by Cicero. "</p>
                <h6 class="text-primary">- Christa Smith <small class="text-muted">Manager</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/05.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" There is now an abundance of readable dummy texts. These are usually used
                  when a text is required. "</p>
                <h6 class="text-primary">- Dean Tolle <small class="text-muted">Developer</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/06.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" Thus, Lorem Ipsum has only limited suitability as a visual filler for
                  German texts. "</p>
                <h6 class="text-primary">- Jill Webb <small class="text-muted">Designer</small></h6>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
        <!-- </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
  <!-- End Testimonial -->
</section>
<!--end section-->
<!-- section End -->

<!--  -->
<section class="section bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">See everything about your employee at one place.</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>

          <div class="mt-4 pt-2">
            <a href="#" target="_blank" class="btn btn-primary">Get Started <span
                class="badge badge-pill badge-danger ml-2">v2.5</span></a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--  -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
