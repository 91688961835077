<!-- Hero Start -->
<section class="bg-half-260 bg-primary d-table w-100"
  style="background: url('assets/images/hosting/bg.png') center center;" id="home">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row mt-5 justify-content-center">
      <div class="col-lg-12">
        <div class="title-heading text-center">
          <h1 class="heading title-dark text-white mb-3">Cloud Services & Web Hosting Solution</h1>
          <p class="para-desc para-dark mx-auto text-muted">Launch your campaign and benefit from our expertise on
            designing and managing conversion centered bootstrap4 html page.</p>
          <div class="mt-4 pt-2">
            <a href="javascript:void(0)" class="btn btn-primary">Get Started</a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Hero End -->

<!-- Search Start -->
<section class="section-two bg-light">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <form class="p-4 shadow bg-white rounded">
          <h4 class="mb-3">Search Your Domain Now</h4>
          <div class="row">
            <div class="col-12">
              <div class="input-group form-group mb-0">
                <input name="name" id="name" type="text" class="form-control rounded-left"
                  placeholder="Your domain name :">
                <div class="input-group-append" id="button-addon4">
                  <select class="form-control rounded-0" id="domain_list">
                    <option>.in</option>
                    <option>.com</option>
                    <option>.org</option>
                    <option>.net</option>
                    <option>.info</option>
                    <option>.me</option>
                  </select>
                  <input type="submit" id="search" name="search" class="searchbtn btn btn-primary btn-block"
                    value="Search">
                </div>
              </div>
            </div>
            <!--end col-->
          </div>
          <!--end row-->

          <div class="row">
            <div class="col-lg-2 col-md-4 col-6 mt-4">
              <a href="javascript:void(0)" class="text-dark">
                <div class="rounded shadow bg-white p-1 text-center">
                  <h6 class="mb-0">.in <br><span class="text-primary">$4.99</span>/year</h6>
                </div>
              </a>
            </div>
            <!--end col-->

            <div class="col-lg-2 col-md-4 col-6 mt-4">
              <a href="javascript:void(0)" class="text-dark">
                <div class="rounded shadow bg-white p-1 text-center">
                  <h6 class="mb-0">.com <br><span class="text-primary">$5.99</span>/year</h6>
                </div>
              </a>
            </div>
            <!--end col-->

            <div class="col-lg-2 col-md-4 col-6 mt-4">
              <a href="javascript:void(0)" class="text-dark">
                <div class="rounded shadow bg-white p-1 text-center">
                  <h6 class="mb-0">.org <br><span class="text-primary">$6.99</span>/year</h6>
                </div>
              </a>
            </div>
            <!--end col-->

            <div class="col-lg-2 col-md-4 col-6 mt-4">
              <a href="javascript:void(0)" class="text-dark">
                <div class="rounded shadow bg-white p-1 text-center">
                  <h6 class="mb-0">.net <br><span class="text-primary">$7.99</span>/year</h6>
                </div>
              </a>
            </div>
            <!--end col-->

            <div class="col-lg-2 col-md-4 col-6 mt-4">
              <a href="javascript:void(0)" class="text-dark">
                <div class="rounded shadow bg-white p-1 text-center">
                  <h6 class="mb-0">.info <br><span class="text-primary">$3.99</span>/year</h6>
                </div>
              </a>
            </div>
            <!--end col-->

            <div class="col-lg-2 col-md-4 col-6 mt-4">
              <a href="javascript:void(0)" class="text-dark">
                <div class="rounded shadow bg-white p-1 text-center">
                  <h6 class="mb-0">.me <br><span class="text-primary">$2.99</span>/year</h6>
                </div>
              </a>
            </div>
            <!--end col-->
          </div>
          <!--end row-->
        </form>
        <!--end form-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Search End -->

<!-- Start Services -->
<section class="section">
  <div class="container pb-lg-4 mb-md-5 mb-4">
    <div class="row align-items-center mb-4">
      <div class="col-lg-9 col-md-8 text-sm-left">
        <div class="section-title">
          <h4 class="title mb-4">Cloud Hosting Services</h4>
          <p class="text-muted para-desc mb-0">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 mt-sm-0 text-sm-right pt-2 pt-sm-0">
        <a href="javascript:void(0)" class="btn btn-outline-primary">Read more <i class="mdi mdi-chevron-right"></i></a>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/computer.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Domain Name</h4>
            <p class="text-muted">Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean
              elementum semper.</p>
            <a href="javascript:void(0)" class="text-success">Read more <i class="mdi mdi-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/cloud.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Cloud Hosting</h4>
            <p class="text-muted">Allegedly, a Latin scholar established the origin of the established text by compiling
              unusual word.</p>
            <a href="javascript:void(0)" class="text-success">Read more <i class="mdi mdi-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/server.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Shared Hosting</h4>
            <p class="text-muted">It seems that only fragments of the original text remain in only fragments the Lorem
              Ipsum texts used today.</p>
            <a href="javascript:void(0)" class="text-success">Read more <i class="mdi mdi-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/big.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">VPS Hosting</h4>
            <p class="text-muted">Nisi aenean vulputate eleifend tellus vitae eleifend enim a Aliquam eleifend aenean
              elementum semper.</p>
            <a href="javascript:void(0)" class="text-success">Read more <i class="mdi mdi-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/database.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Reseller Hosting</h4>
            <p class="text-muted">Allegedly, a Latin scholar established the origin of the established text by compiling
              unusual word.</p>
            <a href="javascript:void(0)" class="text-success">Read more <i class="mdi mdi-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-4 col-md-6 col-12 mt-5 pt-3">
        <div class="features">
          <div class="image position-relative d-inline-block">
            <img src="assets/images/icon/refresh.svg" class="avatar avatar-small" alt="">
          </div>

          <div class="content mt-4">
            <h4 class="title-2">Web Hosting</h4>
            <p class="text-muted">It seems that only fragments of the original text remain in only fragments the Lorem
              Ipsum texts used today.</p>
            <a href="javascript:void(0)" class="text-success">Read more <i class="mdi mdi-chevron-right"></i></a>
          </div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- End Services -->

<!-- How It Work Start -->
<section class="section bg-light">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <img src="assets/images/hosting/1.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ml-lg-5">
          <h4 class="title mb-4">Get best plan for more power with cloud Hosting</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Digital
              Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Our Talented
              & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Create your
              own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="btn btn-primary mt-3">Get Started <i
              class="mdi mdi-chevron-right"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <h4 class="title mb-4">Don't Compromise with the best web hosting solutions</h4>
          <p class="text-muted">Using Landrick to build your site means never worrying about designing another page or
            cross browser compatibility. Our ever-growing library of components and pre-designed layouts will make your
            life easier.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Digital
              Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Our Talented
              & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Create your
              own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="btn btn-primary mt-3">Get Started <i
              class="mdi mdi-chevron-right"></i></a>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <img src="assets/images/hosting/deal-hend.svg" class="img-fluid" alt="">
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <img src="assets/images/hosting/2.png" class="img-fluid" alt="">
      </div>
      <!--end col-->

      <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ml-lg-5">
          <h4 class="title mb-4">Powerful Server & Web Hosting Plateform</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Digital
              Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Our Talented
              & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>Create your
              own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="btn btn-primary mt-3">Get Started <i
              class="mdi mdi-chevron-right"></i></a>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-white">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- How It Work End -->

<!-- Price Start -->
<section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Hosting Rates</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row align-items-center">
      <div class="col-12 mt-4 pt-2">
        <div class="text-center">
          <ul ngbNav #nav="ngbNav"
            class="nav-pills bg-white rounded-lg justify-content-center d-inline-block shadow py-1 px-2 mb-0">
            <li ngbNavItem class="d-inline-block">
              <a ngbNavLink class="nav-link px-3 rounded-lg monthly" id="Monthly" role="tab" aria-controls="Month"
                aria-selected="true">Monthly</a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                    <div class="card pricing-rates bg-light rounded text-center border-0">
                      <div class="card-body py-5">
                        <h2 class="title text-uppercase mb-4">Cloud Hosting</h2>
                        <div class="d-flex justify-content-center mb-4">
                          <span class="h4 mb-0 mt-2">$</span>
                          <span class="price h1 mb-0">0</span>
                          <span class="h4 align-self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-unstyled mb-0 pl-0">
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>2 GB Memory</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>10 Free Optimization</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>24/7 support</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>Content Optimization</li>
                        </ul>
                        <a href="javascript:void(0);" class="btn btn-primary mt-4">Buy Now</a>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                    <div class="card pricing-rates starter-plan bg-white shadow rounded text-center border-0">
                      <div class="card-body py-5">
                        <h2 class="title text-uppercase text-primary mb-4">Dedicated Hosting</h2>
                        <div class="d-flex justify-content-center mb-4">
                          <span class="h4 mb-0 mt-2">$</span>
                          <span class="price h1 mb-0">39</span>
                          <span class="h4 align-self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-unstyled mb-0 pl-0">
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>4 GB Memory</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>10 Free Optimization</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>24/7 support</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>Content Optimization</li>
                        </ul>
                        <a href="javascript:void(0);" class="btn btn-primary mt-4">Get Started</a>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                    <div class="card pricing-rates bg-light rounded text-center border-0">
                      <div class="card-body py-5">
                        <h2 class="title text-uppercase mb-4">VPS Hosting</h2>
                        <div class="d-flex justify-content-center mb-4">
                          <span class="h4 mb-0 mt-2">$</span>
                          <span class="price h1 mb-0">59</span>
                          <span class="h4 align-self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-unstyled mb-0 pl-0">
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>8 GB Memory</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>10 Free Optimization</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>24/7 support</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>Content Optimization</li>
                        </ul>
                        <a href="javascript:void(0);" class="btn btn-primary mt-4">Buy Now</a>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                    <div class="card pricing-rates bg-light rounded text-center border-0">
                      <div class="card-body py-5">
                        <h2 class="title text-uppercase mb-4">Shared Hosting</h2>
                        <div class="d-flex justify-content-center mb-4">
                          <span class="h4 mb-0 mt-2">$</span>
                          <span class="price h1 mb-0">79</span>
                          <span class="h4 align-self-end mb-1">/mo</span>
                        </div>

                        <ul class="list-unstyled mb-0 pl-0">
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>16 GB Memory</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>10 Free Optimization</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>24/7 support</li>
                          <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                                class="uim uim-check-circle"></i></span>Content Optimization</li>
                        </ul>
                        <a href="javascript:void(0);" class="btn btn-primary mt-4">Buy Now</a>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </ng-template>
            </li>
            <li ngbNavItem class="d-inline-block">
              <a ngbNavLink class="nav-link px-3 rounded-lg monthly" id="Yearly" role="tab" aria-controls="Month"
                aria-selected="true">Yearly <span class="badge badge-pill badge-success">15% Off </span></a>
              <ng-template ngbNavContent>
                <div class="row">
                  <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                    <div class="pricing-rates bg-light py-5 p-4 rounded text-center">
                      <h2 class="title text-uppercase mb-4">Cloud Hosting</h2>
                      <div class="d-flex justify-content-center mb-4">
                        <span class="h4 mb-0 mt-2">$</span>
                        <span class="price h1 mb-0">39</span>
                        <span class="h4 align-self-end mb-1">/mo</span>
                      </div>

                      <ul class="list-unstyled mb-0 pl-0">
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>2 GB Memory</li>
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>10 Free Optimization</li>
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>24/7 support</li>
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>Content Optimization</li>
                      </ul>
                      <a href="javascript:void(0);" class="btn btn-primary mt-4">Buy Now</a>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                    <div class="pricing-rates starter-plan shadow bg-white py-5 p-4 rounded text-center">
                      <h2 class="title text-uppercase text-primary mb-4">Dedicated Hosting</h2>
                      <div class="d-flex justify-content-center mb-4">
                        <span class="h4 mb-0 mt-2">$</span>
                        <span class="price h1 mb-0">239</span>
                        <span class="h4 align-self-end mb-1">/mo</span>
                      </div>

                      <ul class="list-unstyled mb-0 pl-0">
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>4 GB Memory</li>
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>10 Free Optimization</li>
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>24/7 support</li>
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>Content Optimization</li>
                      </ul>
                      <a href="javascript:void(0);" class="btn btn-primary mt-4">Get Started</a>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                    <div class="pricing-rates bg-light py-5 p-4 rounded text-center">
                      <h2 class="title text-uppercase mb-4">VPS Hosting</h2>
                      <div class="d-flex justify-content-center mb-4">
                        <span class="h4 mb-0 mt-2">$</span>
                        <span class="price h1 mb-0">359</span>
                        <span class="h4 align-self-end mb-1">/mo</span>
                      </div>

                      <ul class="list-unstyled mb-0 pl-0">
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>8 GB Memory</li>
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>10 Free Optimization</li>
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>24/7 support</li>
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>Content Optimization</li>
                      </ul>
                      <a href="javascript:void(0);" class="btn btn-primary mt-4">Buy Now</a>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                    <div class="pricing-rates bg-light py-5 p-4 rounded text-center">
                      <h2 class="title text-uppercase mb-4">Shared Hosting</h2>
                      <div class="d-flex justify-content-center mb-4">
                        <span class="h4 mb-0 mt-2">$</span>
                        <span class="price h1 mb-0">479</span>
                        <span class="h4 align-self-end mb-1">/mo</span>
                      </div>

                      <ul class="list-unstyled mb-0 pl-0">
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i class="uim uim-check-circle"></i></span>16 GB Memory</li>
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>10 Free Optimization</li>
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>24/7 support</li>
                        <li class="h6 text-muted mb-0"><span class="text-primary h5 mr-2"><i
                              class="uim uim-check-circle"></i></span>Content Optimization</li>
                      </ul>
                      <a href="javascript:void(0);" class="btn btn-primary mt-4">Buy Now</a>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Price End -->

<!-- Testi Start -->
<section class="section pt-md-4 pt-5 pt-sm-0 bg-light">
  <div class="container pb-lg-4 mb-md-5 mb-4">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Client Reviews</h4>
          <p class="text-muted para-desc mx-auto mb-0">Start working with <span
              class="text-primary font-weight-bold">Landrick</span> that can provide everything you need to generate
            awareness, drive traffic, connect.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-lg-12 mt-4">
        <!-- <div id="customer-testi" class="owl-carousel owl-theme"> -->
        <owl-carousel-o id="customer-testi" [options]="customOptions">
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/01.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" It seems that only fragments of the original text remain in the Lorem Ipsum
                  texts used today. "</p>
                <h6 class="text-primary">- Thomas Israel <small class="text-muted">C.E.O</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/02.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star-half text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear
                  more frequently than others. "</p>
                <h6 class="text-primary">- Barbara McIntosh <small class="text-muted">M.D</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/03.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have
                  originated in the 16th century. "</p>
                <h6 class="text-primary">- Carl Oliver <small class="text-muted">P.A</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/04.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" According to most sources, Lorum Ipsum can be traced back to a text
                  composed by Cicero. "</p>
                <h6 class="text-primary">- Christa Smith <small class="text-muted">Manager</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/05.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" There is now an abundance of readable dummy texts. These are usually used
                  when a text is required. "</p>
                <h6 class="text-primary">- Dean Tolle <small class="text-muted">Developer</small></h6>
              </div>
            </div>
          </ng-template>
          <ng-template carouselSlide>
            <div class="media customer-testi m-2">
              <img src="assets/images/client/06.jpg" class="avatar avatar-small mr-3 rounded shadow" alt="">
              <div class="media-body content p-3 shadow rounded bg-white position-relative">
                <ul class="list-unstyled mb-0">
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                  <li class="list-inline-item"><i class="mdi mdi-star text-warning"></i></li>
                </ul>
                <p class="text-muted mt-2">" Thus, Lorem Ipsum has only limited suitability as a visual filler for
                  German texts. "</p>
                <h6 class="text-primary">- Jill Webb <small class="text-muted">Designer</small></h6>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
        <!-- </div> -->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<div class="position-relative">
  <div class="shape overflow-hidden text-footer">
    <svg viewBox="0 0 2880 250" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M720 125L2160 0H2880V250H0V125H720Z" fill="currentColor"></path>
    </svg>
  </div>
</div>
<!-- Testi End -->


<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
  [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->