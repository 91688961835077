import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-re-password',
  templateUrl: './auth-re-password.component.html',
  styleUrls: ['./auth-re-password.component.css']
})
export class AuthRePasswordComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
