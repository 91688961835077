  <!-- Hero Start -->
  <section class="bg-profile d-table w-100 bg-primary" id="home"
    style="background: url('assets/images/account/bg.png') center center;">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="card public-profile border-0 rounded shadow" style="z-index: 1;">
            <div class="card-body">
              <div class="row align-items-center">
                <div class="col-lg-2 col-md-3 text-md-left text-center">
                  <img src="assets/images/client/05.jpg"
                    class="avatar avatar-large rounded-circle shadow d-block mx-auto" alt="">
                </div>
                <!--end col-->

                <div class="col-lg-10 col-md-9">
                  <div class="row align-items-end">
                    <div class="col-md-7 text-md-left text-center mt-4 mt-sm-0">
                      <h3 class="title mb-0">Krista Joseph</h3>
                      <small class="text-muted h6 mr-2">Web Developer</small>
                      <ul class="list-inline mb-0 mt-3">
                        <li class="list-inline-item mr-2"><a href="javascript:void(0)" class="text-muted"
                            title="Instagram">
                            <i-feather name="instagram" class="fea icon-sm mr-2"></i-feather>
                            krista_joseph
                          </a></li>
                        <li class="list-inline-item"><a href="javascript:void(0)" class="text-muted" title="Linkedin">
                            <i-feather name="linkedin" class="fea icon-sm mr-2"></i-feather>
                            Krista Joseph
                          </a></li>
                      </ul>
                    </div>
                    <!--end col-->
                    <div class="col-md-5 text-md-right text-center">
                      <ul class="list-unstyled social-icon social mb-0 mt-4">
                        <li class="list-inline-item"><a href="javascript:void(0)" class="rounded" placement="bottom"
                            ngbTooltip="Add Friend">
                            <i-feather name="user-plus" class="fea icon-sm fea-social"></i-feather>
                          </a></li>
                        <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded" placement="bottom"
                            ngbTooltip="Messages">
                            <i-feather name="message-circle" class="fea icon-sm fea-social"></i-feather>
                          </a></li>
                        <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded" placement="bottom"
                            ngbTooltip="Notifications">
                            <i-feather name="bell" class="fea icon-sm fea-social"></i-feather>
                          </a></li>
                        <li class="list-inline-item ml-1"><a routerLink="/account-setting" class="rounded" placement="bottom"
                            ngbTooltip="Settings">
                            <i-feather name="tool" class="fea icon-sm fea-social"></i-feather>
                          </a></li>
                      </ul>
                      <!--end icon-->
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--ed container-->
  </section>
  <!--end section-->
  <!-- Hero End -->

  <!-- Profile Start -->
  <section class="section mt-60">
    <div class="container mt-lg-3">
      <div class="row">
        <div class="col-lg-4 col-md-6 col-12 d-lg-block d-none">
          <div class="sidebar sticky-bar p-4 rounded shadow">
            <div class="widget">
              <h5 class="widget-title">Followers :</h5>
              <div class="row mt-4">
                <div class="col-6 text-center">
                  <i-feather name="user-plus" class="fea icon-ex-md text-primary mb-1"></i-feather>
                  <h5 class="mb-0">2588</h5>
                  <p class="text-muted mb-0">Followers</p>
                </div>
                <!--end col-->

                <div class="col-6 text-center">
                  <i-feather name="users" class="fea icon-ex-md text-primary mb-1"></i-feather>
                  <h5 class="mb-0">454</h5>
                  <p class="text-muted mb-0">Following</p>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>

            <div class="widget mt-4 pt-2">
              <h5 class="widget-title">Projects :</h5>
              <div class="progress-box mt-4">
                <h6 class="title text-muted">Progress</h6>
                <div class="progress">
                  <div class="progress-bar position-relative bg-primary" style="width:50%;">
                    <div class="progress-value d-block text-muted h6">24 / 48</div>
                  </div>
                </div>
              </div>
              <!--end process box-->
            </div>

            <div class="widget">
              <div class="row">
                <div class="col-6 mt-4 pt-2">
                  <a routerLink="/account-profile" class="accounts rounded d-block shadow text-center py-3">
                    <span class="pro-icons h3 text-muted"><i class="uil uil-dashboard"></i></span>
                    <h6 class="title text-dark h6 my-0">Profile</h6>
                  </a>
                </div>
                <!--end col-->

                <div class="col-6 mt-4 pt-2">
                  <a routerLink="/account-members" class="accounts rounded d-block shadow text-center py-3">
                    <span class="pro-icons h3 text-muted"><i class="uil uil-users-alt"></i></span>
                    <h6 class="title text-dark h6 my-0">Members</h6>
                  </a>
                </div>
                <!--end col-->

                <div class="col-6 mt-4 pt-2">
                  <a routerLink="/account-works" class="accounts active rounded d-block shadow text-center py-3">
                    <span class="pro-icons h3 text-muted"><i class="uil uil-file"></i></span>
                    <h6 class="title text-dark h6 my-0">Works</h6>
                  </a>
                </div>
                <!--end col-->

                <div class="col-6 mt-4 pt-2">
                  <a routerLink="/account-messages" class="accounts rounded d-block shadow text-center py-3">
                    <span class="pro-icons h3 text-muted"><i class="uil uil-envelope-star"></i></span>
                    <h6 class="title text-dark h6 my-0">Messages</h6>
                  </a>
                </div>
                <!--end col-->

                <div class="col-6 mt-4 pt-2">
                  <a routerLink="/account-payments" class="accounts rounded d-block shadow text-center py-3">
                    <span class="pro-icons h3 text-muted"><i class="uil uil-transaction"></i></span>
                    <h6 class="title text-dark h6 my-0">Payments</h6>
                  </a>
                </div>
                <!--end col-->

                <div class="col-6 mt-4 pt-2">
                  <a routerLink="/account-setting" class="accounts rounded d-block shadow text-center py-3">
                    <span class="pro-icons h3 text-muted"><i class="uil uil-setting"></i></span>
                    <h6 class="title text-dark h6 my-0">Settings</h6>
                  </a>
                </div>
                <!--end col-->

                <div class="col-6 mt-4 pt-2">
                  <a routerLink="/auth-login-three" class="accounts rounded d-block shadow text-center py-3">
                    <span class="pro-icons h3 text-muted"><i class="uil uil-sign-out-alt"></i></span>
                    <h6 class="title text-dark h6 my-0">Logout</h6>
                  </a>
                </div>
                <!--end col-->
              </div>
              <!--end row-->
            </div>

            <div class="widget mt-4 pt-2">
              <h5 class="widget-title">Follow me :</h5>
              <ul class="list-unstyled social-icon mb-0 mt-4">
                <li class="list-inline-item"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="github" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="youtube" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
                <li class="list-inline-item ml-1"><a href="javascript:void(0)" class="rounded">
                    <i-feather name="gitlab" class="fea icon-sm fea-social"></i-feather>
                  </a></li>
              </ul>
              <!--end icon-->
            </div>
          </div>
        </div>
        <!--end col-->

        <div class="col-lg-8 col-12">
          <div class="rounded shadow p-4">
            <h5 class="mb-0">Works & Projects: </h5>

            <div class="row projects-wrapper">
              <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 work-container work-classic">
                  <div class="card-body p-0">
                    <a routerLink="/page-work-detail"><img src="assets/images/work/1.jpg"
                        class="img-fluid rounded work-image" alt=""></a>
                    <div class="content pt-3">
                      <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Iphone mockup</a></h5>
                      <h6 class="text-muted tag mb-0">Branding</h6>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 work-container work-classic">
                  <div class="card-body p-0">
                    <a routerLink="/page-work-detail"><img src="assets/images/work/2.jpg"
                        class="img-fluid rounded work-image" alt=""></a>
                    <div class="content pt-3">
                      <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Mockup Collection</a>
                      </h5>
                      <h6 class="text-muted tag mb-0">Mockup</h6>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 work-container work-classic">
                  <div class="card-body p-0">
                    <a routerLink="/page-work-detail"><img src="assets/images/work/3.jpg"
                        class="img-fluid rounded work-image" alt=""></a>
                    <div class="content pt-3">
                      <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Abstract images</a>
                      </h5>
                      <h6 class="text-muted tag mb-0">Abstract</h6>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 work-container work-classic">
                  <div class="card-body p-0">
                    <a routerLink="/page-work-detail"><img src="assets/images/work/4.jpg"
                        class="img-fluid rounded work-image" alt=""></a>
                    <div class="content pt-3">
                      <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Yellow bg with
                          Books</a></h5>
                      <h6 class="text-muted tag mb-0">Books</h6>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 work-container work-classic">
                  <div class="card-body p-0">
                    <a routerLink="/page-work-detail"><img src="assets/images/work/5.jpg"
                        class="img-fluid rounded work-image" alt=""></a>
                    <div class="content pt-3">
                      <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Company V-card</a></h5>
                      <h6 class="text-muted tag mb-0">V-card</h6>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 work-container work-classic">
                  <div class="card-body p-0">
                    <a routerLink="/page-work-detail"><img src="assets/images/work/6.jpg"
                        class="img-fluid rounded work-image" alt=""></a>
                    <div class="content pt-3">
                      <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Mockup box with
                          paints</a>
                      </h5>
                      <h6 class="text-muted tag mb-0">Photography</h6>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 work-container work-classic">
                  <div class="card-body p-0">
                    <a routerLink="/page-work-detail"><img src="assets/images/work/7.jpg"
                        class="img-fluid rounded work-image" alt=""></a>
                    <div class="content pt-3">
                      <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Coffee cup</a></h5>
                      <h6 class="text-muted tag mb-0">Cups</h6>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 work-container work-classic">
                  <div class="card-body p-0">
                    <a routerLink="/page-work-detail"><img src="assets/images/work/8.jpg"
                        class="img-fluid rounded work-image" alt=""></a>
                    <div class="content pt-3">
                      <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Pen and article</a>
                      </h5>
                      <h6 class="text-muted tag mb-0">Article</h6>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 work-container work-classic">
                  <div class="card-body p-0">
                    <a routerLink="/page-work-detail"><img src="assets/images/work/9.jpg"
                        class="img-fluid rounded work-image" alt=""></a>
                    <div class="content pt-3">
                      <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">White mockup box</a>
                      </h5>
                      <h6 class="text-muted tag mb-0">Color</h6>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 work-container work-classic">
                  <div class="card-body p-0">
                    <a routerLink="/page-work-detail"><img src="assets/images/work/10.jpg"
                        class="img-fluid rounded work-image" alt=""></a>
                    <div class="content pt-3">
                      <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Logo Vectors</a></h5>
                      <h6 class="text-muted tag mb-0">Logos</h6>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 work-container work-classic">
                  <div class="card-body p-0">
                    <a routerLink="/page-work-detail"><img src="assets/images/work/11.jpg"
                        class="img-fluid rounded work-image" alt=""></a>
                    <div class="content pt-3">
                      <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Black and white
                          T-shirt</a>
                      </h5>
                      <h6 class="text-muted tag mb-0">Clothes</h6>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 work-container work-classic">
                  <div class="card-body p-0">
                    <a routerLink="/page-work-detail"><img src="assets/images/work/12.jpg"
                        class="img-fluid rounded work-image" alt=""></a>
                    <div class="content pt-3">
                      <h5 class="mb-0"><a routerLink="/page-work-detail" class="text-dark title">Yellow bg with
                          cellphone</a>
                      </h5>
                      <h6 class="text-muted tag mb-0">Cellphone</h6>
                    </div>
                  </div>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
    <!--end container-->
  </section>
  <!--end section-->
  <!-- Profile End -->

  <!-- Back to top -->
  <a href="javascript: void(0);" class="btn btn-icon btn-soft-primary back-to-top" id="back-to-top"
    [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
  </a>
  <!-- Back to top -->
